.login-card{
  background: url('../../images/login/4.jpg') !important;
  opacity: 1;
}

.login-card  .login-main {
  background-color: rgba(255, 0, 0, 0.1) !important
}

.titleLogin{
  background: linear-gradient(45deg, #78adf3, #461724);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 425px) {
  .login-card{
    background: url('../../images/login/5.png') !important;
    opacity: 1;
  }

  
  .login-card .login-main.login-tab{
    height: 425px !important;
    margin-top: 5px !important;
    width: 350px;
  }
 
}

