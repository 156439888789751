.margin-btn-create-employeee {
  margin-bottom: -40px;
  position: absolute;
}

#emplyeesUser::before {
  content: ""
}

// Background of the dx-texteditor into a select-box
.dx-texteditor.dx-editor-filled .dx-placeholder::before,
.dx-texteditor.dx-editor-filled .dx-texteditor-input,
.dx-texteditor.dx-editor-outlined .dx-placeholder::before,
.dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  padding: 15px 16px 9px !important;
  background-color: #FFFFFF;
  height: 38px;
  line-height: 0.45;
}

// Remove underline to dx-select-box
.dx-texteditor.dx-editor-filled::after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
}


// Background de la pestaña de seleccionar
.dx-texteditor-buttons-container {
  background-color: #FFFFFF !important;
  line-height: 0.45 !important;
}


.dx-dropdowneditor-input-wrapper,
.dx-selectbox-container {
  border-radius: 60px !important;
}

.backgroundModalReactContent {
  border: 0;
  background-color: '';
}

.backgroundModalReactOverlay {
  background-color: '';
}

.dx-texteditor.dx-editor-filled::after {
  border-bottom: 0px !important;
}

.dxSelectBorder {
  border-radius: 25px !important;
}

.dx-icon-search {
  position: unset !important;
}


.show-element {
  /*duracion de la animacion, puedes variar el tiempo, entre mas grande mas tarda el efecto*/
  animation-duration: 2s;
  animation-fill-mode: both;
  /*le indicas que animacion quieres aplicarle, animacion hecha por ti en un keyframe*/
  animation-name: fadeIn;
  background-color: rgb(225, 225, 255);
}

.hiddenAddProduct {
  display: none;
}

.uploadPicturesWrapper {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  width: 100% !important;
}

.fileContainer .uploadPictureContainer {
  width: 25%;
  margin: 5%;
  padding: 10px;
  background: #edf2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  // box-shadow: 0 0 8px 2px rgb(0 0 0 / 10%);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid #d0dbe4;
  position: relative;
}

.fileContainer .deleteImage {
  position: absolute;
  top: -9px;
  right: -9px;
  color: #fff;
  background: #ff4081;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  width: 30px;
  height: 30px;
}

.divImg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}



.fileContainer .uploadPictureContainer img.uploadPicture {
  width: 100%;
}

.back {
  position: fixed;
  width: 100%;

  z-index: 1490;
  top: 0;
  left: 0;
  background: #000000cb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.standarSize {
  width: 250px;
  height: 270px;
}

.Toastify__progress-bar--warning {
  background: #E91E63 !important;
  background: lighten($warning-color, 5%) !important;
}

.Toastify__toast--warning {
  background: #E91E63 !important;
  color: #FFFFFF !important;

  .Toastify__progress-bar {
    background: #f8bbd0 !important;
  }
}

.widthSlider {
  width: 700px !important;
}

.widthSlickSlider {
  width: 350px !important;
}

.visibleLogOut {
  display: inline !important;
}

.btnCloseWarning {
  margin-top: -22px !important;
}

.formTitleRegisterCustomer {
  font-size: 30px !important;
  font-weight: bold;
}
.descriptionInfo {
  font-size: 20px !important;
  // font-weight: bold;
}

.descriptionInfoPopup {
  font-size: 18px !important;
  // font-weight: bold;
}

.widthRealPrice input{
  width: 200px !important;
  max-width: 200px !important;
}

@media (max-width: 3000px) {
  .note {
    min-width: 450px !important;
    min-height: 300px !important;
    // width: 450px !important;
  }
  .img-fluid{
    max-width: 100%;
    height: auto;
    max-height: 600px !important;
  }
}




@media (max-width: 1199px) {
  .note {
    min-width: 200px !important;
    min-height: 200px !important;
    width: 400px !important;
  }
  .img-fluid{
    max-width: 100%;
    height: auto;
    max-height: 600px !important;
  }
}

@media (max-width: 991.98px) {
  .titleModalP {
    margin-top: 10px;
    margin-left: 10px;
  }

  .img-fluid{
    max-width: 100% !important;
    height: auto !important;
    max-height: 475px !important;
  }
}

@media (max-width: 425px) {
  .note {
    min-width: 100px !important;
    max-width: 280px !important;
    min-height: 370px !important;
  }
}

@media (max-width: 375px) {
  .note {
    min-width: 200px !important;
    min-height: 410px !important;
    max-width: 250px !important;
  }
}

@media (max-width: 320px) {
  .note {
    min-width: 200px !important;
    min-height: 410px !important;
    max-width: 225px !important;
    max-height: 450px !important;

  }
}

.leftPermission {
  float: center;
  // width: 330px;
  height: 460px;
  padding: 20px;
  background: rgba(191, 191, 191, 0.15);
  // margin-right: 30px;
}

.leftPermissionModule {
  float: center;
  height: 550px;
  padding: 20px;
  background: rgba(191, 191, 191, 0.15);
}
.leftPermissionModule .list .dx-list-group-header {
  color: #f05b41;
  font-weight: normal;
  font-size: 18px;
}

.leftPermission .list .dx-list-group-header {
  color: #f05b41;
  font-weight: normal;
  font-size: 18px;
}

.titleModule {
  font-size: 27px;
  font-weight: bold;
}

.descriptionBackground {
  padding: 10px;
  background-color: #dad6ff;
  transition: all 0.5s ease;
  border-radius: 10px;
}

.descriptText {
  color: #000000 !important;
}

.nameFunction {
  color: #000000 !important;
  font-weight: bold;
}
.dx-texteditor.dx-editor-filled {
  background-color: rgba(0,0,0,0) !important; 
  height: 45px !important;
}

.master-detail-caption {
  padding: 0 0 5px 10px;
  font-size: 14px;
  font-weight: bold;
}